import { render, staticRenderFns } from "./Lesson.vue?vue&type=template&id=65f4398d&scoped=true&"
import script from "./Lesson.vue?vue&type=script&lang=js&"
export * from "./Lesson.vue?vue&type=script&lang=js&"
import style0 from "./Lesson.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Lesson.vue?vue&type=style&index=1&id=65f4398d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65f4398d",
  null
  
)

export default component.exports