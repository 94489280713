<template>
  <div>
    <!-- SECTION - REVIEW DIALOG -->
    <base-dialog :show="displayDialog" @close="closeReview">
      <div class="review text-center">
        <h4 class="text-big text-line color-primary font-weight-bold">
          <span>รีวิวคอร์สเรียน</span>
        </h4>
        <div v-if="reviewPage === 1">
          <p class="text-big font-weight-bold">เนื้อหาของคอร์สเรียน</p>
          <div class="row justify-content-center mb-3">
            <div class="choice text-big">
              <button
                class="mx-auto"
                :class="{ selected: review[0] === 0 }"
                @click="setReview(0, 0)"
              >
                เกินความคาดหวังไว้มาก
              </button>
            </div>
          </div>
          <div class="row justify-content-center mb-3">
            <div class="choice text-big">
              <button
                class="mx-auto"
                :class="{ selected: review[0] === 1 }"
                @click="setReview(0, 1)"
              >
                เกินความคาดหวัง
              </button>
            </div>
          </div>
          <div class="row justify-content-center mb-3">
            <div class="choice text-big">
              <button
                class="mx-auto"
                :class="{ selected: review[0] === 2 }"
                @click="setReview(0, 2)"
              >
                ตรงตามที่คาดหวัง
              </button>
            </div>
          </div>
        </div>
        <div v-if="reviewPage === 2">
          <p class="text-big font-weight-bold">ความพึงพอใจของคอร์สเรียน</p>
          <div class="row">
            <div class="col-12 col-lg-6 text-big mb-3">
              <button
                class="mx-auto ml-lg-auto mr-lg-0"
                :class="{ selected: review[1] === 0 }"
                @click="setReview(1, 0)"
              >
                เนื้อหาของคอร์สเรียน
              </button>
            </div>
            <div class="col-12 col-lg-6 text-big mb-3">
              <button
                class="mx-auto mr-lg-auto ml-lg-0"
                :class="{ selected: review[1] === 1 }"
                @click="setReview(1, 1)"
              >
                ผู้สอน
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6 text-big mb-3">
              <button
                class="mx-auto ml-lg-auto mr-lg-0"
                :class="{ selected: review[1] === 2 }"
                @click="setReview(1, 2)"
              >
                การยกตัวอย่าง ในการสอน
              </button>
            </div>
            <div class="col-12 col-lg-6 text-big mb-3">
              <button
                class="mx-auto mr-lg-auto ml-lg-0"
                :class="{ selected: review[1] === 3 }"
                @click="setReview(1, 3)"
              >
                รูปแบบการจัดเรียงเนื้อหา
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6 text-big mb-3">
              <button
                class="mx-auto ml-lg-auto mr-lg-0"
                :class="{ selected: review[1] === 4 }"
                @click="setReview(1, 4)"
              >
                เทคนิคการสอน
              </button>
            </div>
            <div class="col-12 col-lg-6 text-big mb-3">
              <button
                class="mx-auto mr-lg-auto ml-lg-0"
                :class="{ selected: review[1] === 5 }"
                @click="setReview(1, 5)"
              >
                ความเหมาะสมของเนื้อหา
              </button>
            </div>
          </div>
        </div>
        <div v-if="reviewPage === 3">
          <p class="text-big font-weight-bold">ประเมินความพึงพอใจโดยรวม</p>
          <div>
            <b-form-rating
              v-model="userRating"
              inline
              class="mt-2 mb-5"
              style="color: #0430AD;"
              no-border
              size="lg"
            ></b-form-rating>
          </div>
          <div class="mx-lg-5">
            <textarea
              v-model="userComment"
              rows="5"
              class="w-100 p-2"
              placeholder="ข้อเสนอแนะเพิ่มเติม"
            ></textarea>
          </div>
        </div>
        <button class="next-page" @click="nextReviewPage" v-if="reviewPage < 3">
          ต่อไป
        </button>
        <button class="next-page" @click="submitReview" v-else>
          ยืนยัน
        </button>
        <p
          v-if="reviewPage > 1"
          class="text-big"
          style="color: #C4C4C4; cursor: pointer;"
          @click="prevReviewPage"
        >
          ย้อนกลับ
        </p>
      </div>
    </base-dialog>
    <!-- SECTION - Video player -->
    <div class="container">
      <div class="d-flex align-items-center mb-3">
        <h4 class="color-primary font-weight-bold mr-3 mb-0">
          {{ courseDetail ? courseDetail.title : "" }}
        </h4>
        <div
          class="d-none d-lg-block"
          style="background: #0430AD; width:10px; height:10px; transform:rotate(45deg)"
        ></div>
        <div
          class="d-none d-lg-block flex-fill"
          style="border-top: 3px solid #0430AD;"
        />
      </div>
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="video-container">
            <div
              v-show="videoLoading"
              class="video-loader d-flex justify-content-center align-items-center"
            >
              <b-spinner></b-spinner>
            </div>
            <iframe
              class="video-frame"
              v-show="!videoLoading"
              id="stream-player"
              src=""
              allow="gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
            ></iframe>
          </div>
          <p class="text-secondary mt-2">
            {{ courseDetail ? courseDetail.description : "" }}
          </p>
          <div v-if="courseDetail && courseDetail.file" class="download-sheet">
            <a
              class="d-flex align-items-center"
              :href="courseDetail.file"
              target="_blank"
              download
            >
              <img class="mr-2" src="../assets/images/pdf.png" alt="" />
              <p class="color-primary">ดาวน์โหลดเอกสารประกอบการเรียน</p>
            </a>
          </div>
          <div class="d-flex my-5 mb-lg-0 instructor align-items-center">
            <img
              v-if="lecturerDetail && lecturerDetail.img_url"
              class="ml-lg-0 mr-3 mb-3"
              :src="lecturerDetail.img_url"
              alt=""
            />
            <img
              v-else
              class="ml-lg-0 mr-3 mb-3"
              src="../assets/images/profile/noprofile.png"
              alt=""
            />
            <div>
              <p class="font-weight-bold color-primary text-big m-0">
                {{
                  lecturerDetail
                    ? `${lecturerDetail.first_name} ${lecturerDetail.last_name}`
                    : ""
                }}
              </p>
              <p class="color-text">
                {{ lecturerDetail ? lecturerDetail.detail : "" }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="learning-progress text-white mb-3">
            <p class="text-big">{{ courseDetail ? courseDetail.title : "" }}</p>
            <div class="d-flex align-items-center mb-3">
              <b-progress
                class="w-100 mr-2"
                height="10px"
                :value="learningPercent"
                :max="100"
                :precision="2"
                variant="success"
              ></b-progress>
              <img
                class="d-block"
                src="../assets/images/checknull.png"
                alt=""
              />
            </div>
            <p class="m-0 text-small">เรียนไปแล้ว {{ learningPercent }}%</p>
          </div>
          <p class="color-primary text-big font-weight-bold my-3">
            เนื้อหาทั้งหมด
          </p>
          <div class="content-list">
            <div
              class="item"
              v-for="content in videoList"
              :key="content.name"
              @click="goToLesson(content)"
              :style="[
                $route.query.video === content.uid
                  ? {
                      backgroundColor: '#D4D4D4',
                    }
                  : null,
              ]"
            >
              <div class="d-flex">
                <img class="mt-1 mr-2" src="../assets/images/play.svg" alt="" />
                <div>
                  <p class="text-big font-weight-bold">{{ content.name }}</p>
                  <p class="m-0">
                    ใช้เวลา {{ secToMin(content.duration) }}
                    {{ progressText(content.isEnd) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            class="d-flex my-3 w-100 align-items-center rating"
            @click="displayDialog = true"
            :disabled="learningPercent < 100"
          >
            <img src="../assets/images/rating.svg" alt="" />
            <p class="m-0 text-white text-big">
              รีวิวคอร์สเรียน {{ isReviewed ? "(รีวิวแล้ว)" : "" }}
            </p>
          </button>
        </div>
      </div>
      <hr style="border-top: 3px solid #0430AD;margin: 50px 30%;" />
    </div>
    <!-- SECTION - COMMENT -->
    <div class="container">
      <textarea
        name=""
        rows="5"
        class="w-100"
        v-model="comment"
        placeholder="เขียนคอมเมนต์ข้อความ"
      ></textarea>
      <button class="secondary d-block ml-auto" @click="addComment">
        <p class="text-big">
          คอมเมนต์
        </p>
      </button>
      <!-- Nut Start Implements Here -->
      <h2 class="text-big color-primary ">คอมเมนต์</h2>
      <div class="comment" ref="comment" v-if="commentList.length > 0">
        <!--<div v-for="comment in commentList" :key="comment.userId">
          <div class="comment-card">
            <div class="d-flex align-items-center mb-3">
              <div class="profile-pic mr-2">
                <img
                  class="w-100"
                  src="../assets/images/profile/noprofile.png"
                  alt=""
                />
              </div>
              <p class="text-big font-weight-bold m-0">
                {{ comment.username }}
              </p>
            </div>
            <p>
              {{ comment.comment }}
            </p>
            <div class="d-flex">
              <p class="mb-0 mr-5">{{ comment.like }}</p>
              <p class="mb-0">ตอบกลับ ({{ comment.reply.length }})</p>
            </div>
          </div>
          <div v-show="true" v-for="reply in comment.reply" :key="reply.userId">
            <div class="comment-card ml-5">
              <div class="d-flex align-items-center mb-3">
                <div class="profile-pic mr-2">
                  <img
                    class="w-100"
                    src="../assets/images/profile/noprofile.png"
                    alt=""
                  />
                </div>
                <p class="text-big font-weight-bold m-0">
                  {{ reply.username }}
                </p>
              </div>
              <p>
                {{ reply.comment }}
              </p>
              <div class="d-flex">
                <p class="mb-0 mr-5">{{ reply.like }}</p>
                <p class="mb-0">ตอบกลับ</p>
              </div>
            </div>
          </div>
        </div>-->
        <Comment
          v-for="comment in commentList"
          :key="comment.comment_id"
          :comment="comment"
          :userid="userId"
          v-on:reload="getComment()"
        />
      </div>
      <div v-else>
        <h4 class="color-text text-center font-weight-normal">ไม่มีคอมเมนต์</h4>
      </div>
    </div>
    <!-- SECTION - Related courses -->
    <div class="container">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h4 class="color-primary font-weight-bold mr-3 mb-0">หลักสูตรแนะนำ</h4>
        <router-link class="more-course" :to="{ name: 'Curriculum' }"
          ><p class="text-white text-big font-weight-bold m-0">
            ดูเพิ่มเติม
          </p></router-link
        >
        <!-- <button class="more-course">
          <p class="text-white text-big font-weight-bold m-0">ดูเพิ่มเติม</p>
        </button> -->
      </div>
      <div class="row" v-if="suggestList.length > 0">
        <div
          v-for="course in suggestList"
          :key="course.id"
          class="col-12 col-md-4 my-2"
        >
          <CourseCard :course="course" type="curriculum" />
        </div>
      </div>
      <div v-else>
        <h3 class="color-text text-center">ไม่มีหลักสูตรแนะนำ</h3>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CourseCard from "../components/CourseCard.vue";
import Comment from "../components/Comment.vue";
import axios from "../api/faAPI";
import normAxios from "axios";
import baseUrl from "../util/backend";
import moment from "moment";
import { secToMin } from "../util/convertTime";
export default {
  components: {
    CourseCard,
    Comment,
  },
  data() {
    return {
      mainLoading: false,
      courseLoading: false,
      cancelToken: null,
      commentInterval: null,
      videoLoading: false,
      courseDetail: null,
      lecturerDetail: null,
      videoList: [],
      comment: "",
      commentList: [],
      commentData: null,
      suggestList: [],
      time: "",
      lastStop: 0,
      relatedCourse: [],
      player: null,
      vidLink: "",
      learningPercent: 0,
      reviewPage: 1,
      displayDialog: false,
      playerInterval: null,
      isReviewed: false,
      review: [0, 0],
      userComment: "",
      userRating: 5,
    };
  },
  computed: {
    userId() {
      return this.$store.getters.getUser.user_id;
    },
    currentVideo() {
      return this.videoList.find((video) => video.id == this.$route.query.vid);
    },
  },
  watch: {
    "$route.params"() {
      this.loadCourseStudy();
    },
  },
  methods: {
    secToMin,
    goToLesson(video) {
      // console.log("hell no");
      // console.log(video);
      this.$router.push({
        name: "Lesson",
        params: { courseId: this.$route.params.courseId },
        query: { vid: video.id, video: video.uid },
      });
    },
    progressText(isEnd) {
      if (isEnd === true) {
        return "- สำเร็จแล้ว";
      } else if (isEnd === false) {
        return "- กำลังเรียนอยู่";
      } else {
        return "";
      }
    },
    setReview(index, value) {
      this.review.splice(index, 1, value);
    },
    nextReviewPage() {
      this.reviewPage += 1;
    },
    prevReviewPage() {
      this.reviewPage -= 1;
    },
    closeReview() {
      this.displayDialog = false;
      this.reviewPage = 1;
    },
    async submitReview() {
      const res = await axios.post(`${baseUrl}/createReview`, {
        user_id: this.userId,
        course_id: this.$route.params.courseId,
        content: this.review[0],
        detail: this.review[1],
        rating: this.userRating,
        comment: this.userComment,
      });
      console.log(res);
      this.displayDialog = false;
      this.reviewPage = 1;
      this.isReviewed = true;
    },
    setVideoTime() {
      if (this.lastStop) {
        if (this.player.currentTime < 0.01) {
          console.log("setVideoTime");
          this.player.currentTime = this.lastStop;
          this.time = this.lastStop;
          if (!this.player.paused) {
            this.player.play();
          }
        }
      }
    },
    async recordPlayTime() {
      this.playerInterval = setInterval(() => {
        // console.log(this.player.currentTime);
        this.time = this.player.currentTime;
        this.updateVideoLog();
      }, 5000);
    },
    stopRecodeTime() {
      console.log("pause!");
      clearInterval(this.playerInterval);
    },
    async updateVideoLog() {
      let percentFinish =
        (this.player.currentTime / this.player.duration) * 100;
      console.log("this.currentVideo.is_end", this.currentVideo.isEnd);
      const updateRes = await axios.put(`${baseUrl}/updateVideo_log`, {
        video_id: +this.$route.query.vid,
        user_id: this.userId,
        second: this.player.currentTime,
        is_end: this.currentVideo.isEnd
          ? this.currentVideo.isEnd
          : percentFinish > this.currentVideo.percentEnd,
      });
      this.loadProgress();
    },
    async loadVideo() {
      this.videoLoading = true;
      const CancelToken = normAxios.CancelToken;
      this.cancelToken = CancelToken.source();
      try {
        const res = await axios.post(
          `${baseUrl}/getSignedTokens`,
          {
            video_uid: this.$route.query.video,
          },
          {
            cancelToken: this.cancelToken.token,
          }
        );
        let newUrl = `https://iframe.videodelivery.net/${res.data.token}`;
        this.player = Stream(document.getElementById("stream-player"));
        let iFrame = document.querySelector("#stream-player");
        let iFrameParent = iFrame.parentElement;
        iFrame.remove();
        iFrame.setAttribute("src", newUrl);
        iFrameParent.append(iFrame);
        //---- set event listener for checking user view timestamp ----//
        this.player.addEventListener("play", this.recordPlayTime);
        this.player.addEventListener("pause", this.stopRecodeTime);
        this.player.addEventListener("canplay", this.setVideoTime);
        this.player.addEventListener("ended", this.updateVideoLog);
        //---- set event listener for checking user view timestamp ----//
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          if (normAxios.isCancel(err)) {
            return;
          } else {
            alert(err);
          }
        }
      }
      this.videoLoading = false;
    },
    async addComment() {
      if (this.comment) {
        let commentReq = {
          user_id: this.userId,
          comment: this.comment,
          course_id: this.$route.params.courseId,
        };
        try {
          await axios.post(`${baseUrl}/createComment`, commentReq);
          this.comment = "";
          await this.getComment();
          let test = this.$refs.comment;
          test.scrollTop = test.scrollHeight;
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      }
    },
    async loadComment() {
      this.commentList = this.commentData;
      console.log("loadComment");
    },
    async getComment() {
      try {
        const res = await axios.get(`${baseUrl}/getAllComment`, {
          params: {
            course_id: this.$route.params.courseId,
            user_id: this.userId,
          },
        });
        this.commentData = res.data.data;
        this.loadComment();
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
    },
    async loadSuggest() {
      const res = await axios.get(`${baseUrl}/getSuggestCurriculum`);
      this.suggestList = res.data.data;
    },
    async loadProgress() {
      const res = await axios.get(`${baseUrl}/getCourseStudy`, {
        params: {
          course_id: this.$route.params.courseId,
          user_id: this.userId,
        },
      });
      const courseDetail = res.data.courseDetail;
      this.learningPercent = Math.ceil(courseDetail.progress);
      const videoArr = [];
      for (const video of courseDetail.playlist_result) {
        videoArr.push({
          id: video.playlist.video.video_id,
          name: video.playlist.video.name,
          duration: video.playlist.video.second,
          uid: video.playlist.video.url,
          percentEnd: video.playlist.video.percent_end,
          isEnd: video.is_end,
        });
      }
      this.videoList = videoArr;
    },
    async loadCourseStudy() {
      this.courseLoading = true;
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      try {
        const res = await axios.get(`${baseUrl}/getCourseStudy`, {
          params: {
            course_id: this.$route.params.courseId,
            user_id: this.userId,
          },
        });
        console.log(res.data.courseDetail);
        const courseDetail = res.data.courseDetail;
        if (!this.$route.query.video) {
          if (courseDetail.playlist_result.length > 0) {
            if (courseDetail.latest_video) {
              this.$router.replace({
                name: "Lesson",
                params: { courseId: this.$route.params.courseId },
                query: {
                  vid: courseDetail.latest_video.video_id,
                  video: courseDetail.latest_video.url,
                },
              });
            } else {
              this.$router.replace({
                name: "Lesson",
                params: { courseId: this.$route.params.courseId },
                query: {
                  vid: courseDetail.playlist_result[0].playlist.video.video_id,
                  video: courseDetail.playlist_result[0].playlist.video.url,
                },
              });
            }
          } else {
            this.courseLoading = false;
            alert("ไม่มีวิดิโอในคอร์สนี้");
            this.$router.replace({ name: "MyCourse" });
          }
        } else {
          this.courseDetail = courseDetail.course.course;
          this.lecturerDetail = courseDetail.course.lecturer;
          this.learningPercent = Math.ceil(courseDetail.progress);
          const videoArr = [];
          for (const video of courseDetail.playlist_result) {
            videoArr.push({
              id: video.playlist.video.video_id,
              name: video.playlist.video.name,
              duration: video.playlist.video.second,
              uid: video.playlist.video.url,
              percentEnd: video.playlist.video.percent_end,
              isEnd: video.is_end,
            });
          }
          this.videoList = videoArr;
          // let check = false;
          // for await (const video of this.videoList) {
          //   if (+this.$route.query.vid === video.id) {
          //     check = true;
          //   }
          // }
          // if (!check) {
          //   this.$router.replace({
          //     name: "Lesson",
          //     params: { courseId: this.$route.params.courseId },
          //     query: {
          //       vid: courseDetail.playlist_result[0].playlist.video.video_id,
          //       video: courseDetail.playlist_result[0].playlist.video.url,
          //     },
          //   });
          // }
          try {
            const logRes = await axios.get(`${baseUrl}/getVideo_log`, {
              params: {
                course_id: this.$route.params.courseId,
                user_id: this.$store.getters.getUser.user_id,
                video_id: +this.$route.query.vid,
              },
            });
            console.log(logRes.data.data);
            this.lastStop = logRes.data.data.second || 0;
          } catch (err) {
            if (err.response) {
              console.log(err.response.data.error);
              if (
                err.response.data.error ===
                "Doesn't exist video_log in database"
              ) {
                const res = await axios.post(`${baseUrl}/createVideo_log`, {
                  user_id: this.$store.getters.getUser.user_id,
                  video_id: +this.$route.query.vid,
                  course_id: this.$route.params.courseId,
                });
                console.log(res);
                this.lastStop = 0;
              }
            } else {
              throw err;
            }
          }
          this.loadVideo();
          this.getComment();
          this.commentInterval = setInterval(() => {
            this.getComment();
          }, 10000);
        }
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
      this.courseLoading = false;
    },
    async checkOwnedCourse() {
      let livedCourse = [];
      const res = await axios.get(`${baseUrl}/getUserCourse`, {
        params: { user_id: this.$store.getters.getUser.user_id },
      });
      const timeRes = await axios.get(`${baseUrl}/getTime`);
      console.log(timeRes.data.type.unix);
      const currTime = timeRes.data.type.unix;
      let boughtCourse = res.data.data;
      for (const course of boughtCourse) {
        const dateNow = moment(currTime);
        const expireDate = course.course_expire
          ? moment(course.course_expire).unix()
          : null;
        console.log(expireDate);
        if (expireDate) {
          if (moment.unix(dateNow) < moment.unix(expireDate)) {
            livedCourse.push(course.course.course_id);
          }
        } else {
          livedCourse.push(course.course.course_id);
        }
      }
      if (!livedCourse.includes(+this.$route.params.courseId)) {
        throw "คุณไม่มีคอร์สนี้";
      }
    },
    async checkIsReview() {
      const res = await axios.get(`${baseUrl}/getReview`, {
        params: { course_id: this.$route.params.courseId },
      });
      for (const review of res.data.review) {
        if (review.user_id === this.$store.getters.getUser.user_id) {
          this.isReviewed = true;
        }
      }
    },
    async loadLessonData() {
      this.mainLoading = true;
      try {
        await this.checkOwnedCourse();
        this.loadCourseStudy();
        this.loadSuggest();
        this.checkIsReview();
      } catch (err) {
        alert(err);
        this.$router.replace({ name: "Home" });
      }
      this.mainLoading = false;
    },
  },
  created() {
    this.loadLessonData();
    // this.loadVideo();
    //this.commentData = comments;
    // this.getComment();
  },
  beforeRouteUpdate(_, _2, next) {
    clearInterval(this.commentInterval);
    if (this.player) {
      this.stopRecodeTime();
      this.player.removeEventListener("play", this.recordPlayTime);
      this.player.removeEventListener("pause", this.stopRecodeTime);
      this.player.removeEventListener("ended", this.updateVideoLog);
      this.player.destroy();
    }
    next();
  },
  beforeRouteLeave(_, _2, next) {
    if (this.mainLoading || this.courseLoading) {
      next(false);
    } else {
      next();
    }
  },
  beforeDestroy() {
    this.stopRecodeTime();
    this.cancelToken.cancel();
    clearInterval(this.commentInterval);
  },
};
</script>
<style>
/* .progress-bar {
  background-color: red;
} */
</style>

<style scoped>
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

textarea {
  outline: none;
  padding: 10px;
}

/* bootstrap */
.container {
  margin-bottom: 100px;
}
/* bootstrap */

/* vue bootstrap progressbar */
.progress {
  background-color: #c4c4c4;
}
/* vue bootstrap progressbar */

.video-container {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}

.video-loader {
  background-color: #c7c7c7;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.video-frame {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.download-sheet {
  cursor: pointer;
  border: 1px solid #0430ad;
  width: fit-content;
  padding: 10px;
}

.download-sheet p {
  margin: 0;
}

.instructor img {
  display: block;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.learning-progress {
  padding: 10px 15px;
  background-color: #798da5;
}

.content-list {
  border: 1px solid #d5d5d5;
  height: 300px;
  overflow: auto;
}

/* width */
.content-list::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.content-list::-webkit-scrollbar-track {
  background: #cccccc;
}

/* Handle */
.content-list::-webkit-scrollbar-thumb {
  background: #07c5f8;
  border-radius: 3.5px;
}

/* Handle on hover */
.content-list::-webkit-scrollbar-thumb:hover {
  background: #07c5f8;
}

.content-list .item {
  cursor: pointer;
  border-bottom: 1px solid #d5d5d5;
  padding: 10px;
}

.content-list img {
  width: 18px;
  height: 18px;
}

.rating {
  padding: 10px;
  background-color: #07c5f8;
  cursor: pointer;
}

.rating:disabled {
  background-color: #d5d5d5;
  cursor: default;
}

.rating img {
  padding-right: 15px;
}
.qanda img {
  padding-right: 10px;
}

button.secondary {
  color: #fff;
  background-color: #07c5f8;
  padding: 10px 30px;
}

/* Comment */
.comment {
  max-height: 500px;
  overflow-y: auto;
}

.comment::-webkit-scrollbar {
  width: 10px;
}

.comment::-webkit-scrollbar-track {
  background: #a1a1a1;
  border-radius: 10px;
}

.comment::-webkit-scrollbar-thumb {
  background: #07c5f8;
  border-radius: 5px;
}
.comment-card {
  background-color: #f1f1f1;
  padding: 20px;
  margin: 10px;
}

.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  line-height: 0;
}

.more-course {
  cursor: pointer;
  border: none;
  background-color: #07c5f8;
  padding: 10px 25px;
}

/* review dialog */
.review hr {
  border-top: 3px solid #0430ad;
  margin: 20px 25%;
}

.review textarea {
  border: 2px solid #0430ad;
  outline: none;
}

.review textarea::-webkit-input-placeholder {
  text-align: center;
  color: #07c5f8;
  font-size: 18px;
}

.review textarea:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
  color: #07c5f8;
  font-size: 18px;
}

.review textarea::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
  color: #07c5f8;
  font-size: 18px;
}

.review textarea:-ms-input-placeholder {
  text-align: center;
  color: #07c5f8;
  font-size: 18px;
}

.review button {
  display: block;
  background-color: #c4c4c4;
  width: 100%;
  border: none;
  color: white;
  padding: 10px 15px;
  /* margin: 10px auto; */
  max-width: 400px;
}

.review button.selected {
  background-color: #0430ad;
}

.text-line {
  width: 100%;
  max-width: 400px;
  text-align: center;
  border-bottom: 3px solid #0430ad;
  line-height: 0.1em;
  margin: 40px auto;
}

.text-line span {
  background: #fff;
  padding: 0 10px;
}

* >>> .form-control:focus {
  box-shadow: none !important;
}

.choice {
  display: block;
  margin: 0 10px;
  background-color: #c4c4cc;
  width: 100%;
  max-width: 400px;
}

.review >>> .b-rating-star {
  font-size: 2rem;
}

.review .next-page {
  display: block;
  background-color: #07c5f8;
  border: none;
  color: white;
  min-width: 100px;
  max-width: 150px;
  padding: 10px 15px;
  margin: 50px auto 10px auto;
}

@media only screen and (min-width: 992px) {
  .review button {
    padding: 20px 15px;
  }
}
/* review dialog */
</style>
